<template>
<div style="margin: 30px;">
  <h1>Test des API</h1>
  <b-form-group label='Url'>
    <b-input type="text" v-model="url" />
  </b-form-group>
  <b-form-group label="Arguments (json)">
    <b-form-textarea v-model="json" rows="6" max-rows="12"></b-form-textarea>
  </b-form-group>
  <b-form-group label="Résultats">
    <div v-show="running">Exécution en cours...</div>
    <b-form-textarea v-show='result!=""' v-model="result" rows="6" max-rows="12"></b-form-textarea>
    <div v-show="error!=''">{{ error }}</div>
  </b-form-group>
  <b-form-group label="Cookie de session">
    {{ getCookie('session') }}
  </b-form-group>

  <b-button @click='submit'>Soumettre</b-button>
</div>
</template>

<script>
import axios from "axios";

export default {
  data(){
    return {
      url: '/api/',
      json: '',
      result: '',
      error: '',
      running: false,
    }
  },
  methods:{
    getCookie(cname){
      console.log('cookies', document.cookie);
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    submit(){
      this.result = '';
      this.error = '';
      try{
        var axiosUrl = this.getUrl(this.url);

        var jsonStr = "{" + this.json + "}";
        var jsonObj = JSON.parse(jsonStr);

        this.running = true;
        axios
          .post(axiosUrl, jsonObj)
          .then(response => {
            console.log('testApi response', response);
            this.result = response.data;
            this.running = false;
          })
          .catch(error => {
            this.error = error;
            console.log('testApi error', error);
            this.running = false;
          });
      } catch(exception){
        console.log('testApi exceptionn before ajax', exception);
        this.error = exception;
        this.running = false;
      }
    }
  },
  mounted(){
    this.$hideLoader();
  }
}
</script>
